
import Vue from 'vue';

interface Computed {
    truncatedValue: string;
}

export default Vue.extend<unknown, unknown, Computed, any>({
    props: {
        asset: {
            type: String,
            required: true,
        },
        value: {
            type: [Number, String],
            required: true,
        },
        precision: {
            type: Number,
            default: 8,
        },
        needToRound: {
            type: Boolean,
            default: true,
        },
        assetClass: {
            type: undefined,
        },
        valueClass: {
            type: undefined,
        },
        isAlignedRight: {
            type: Boolean,
            default: false,
        },
        isAlignedLeft: {
            type: Boolean,
            default: false,
        },
        isAlignedCenter: {
            type: Boolean,
            default: false,
        },
        prefix: {
            type: String,
            default: '',
        },
        isReverse: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        truncatedValue() {
            return Number(this.value).toFixed(this.precision).getSpaceSeparatedDigits();
        },
    },
});
