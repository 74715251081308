var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
        _vm.s.row,
        {
            [_vm.s.justifyContentCenter]: _vm.isAlignedCenter,
            [_vm.s.justifyContentLeft]: _vm.isAlignedLeft,
            [_vm.s.justifyContentRight]: _vm.isAlignedRight,
            [_vm.s.reverse]: _vm.isReverse,
        }
    ]},[_c('span',{class:[_vm.s.assetText, { [_vm.assetClass]: _vm.assetClass }]},[_vm._v(" "+_vm._s(_vm.asset)+" ")]),_c('span',{class:[_vm.s.valueText, { [_vm.valueClass]: _vm.valueClass }]},[_vm._v(" "+_vm._s(_vm.prefix)+_vm._s(_vm.needToRound ? _vm.truncatedValue : _vm.value)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }